import React from "react"
import { PageProps } from "gatsby"
import styled from "styled-components"

import SEO from "../components/seo"
import { GridContainer } from "../components/grid"
import { H1, H2, Subtitle } from "../components/text"
import { PrimaryLink } from "../components/links"
import VideoContainer from "../components/containers/video-container"

const WelcomeContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 2rem 0;
  }
`

const WelcomeTitle = styled(H1)`
  grid-column: 1 / -1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 3 / span 8;
  }
`

const WelcomeVideoContainer = styled(VideoContainer)`
  grid-column: 1 / -1;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 2 / span 10;
  }
`

const WelcomeTextContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  padding-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 3 / span 8;
    gap: 2rem 0;
  }
`

const ProgramContainer = styled.div`
  grid-column: 1 / -1;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem 0;
`

const DonwloadLink = styled(PrimaryLink)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.beukelaarGreen};
  font-weight: bold;
`

const ProgramTitle = styled(H2)``

const ProgramVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
`

interface ProgramVideoProps {
  title: string
  src: string
}

const ProgramVideo: React.FC<ProgramVideoProps> = ({ title, src }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.5rem",
      }}
    >
      <VideoContainer title={title} src={src} />
      <p>{title}</p>
    </div>
  )
}

const IndexPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Samen in HRM"
      description="Al meer dan 20 jaar begeleiden wij organisaties, teams, en professionals met complexe vraagstukken. Van organisatieontwerp tot coaching; als je kiest voor samenwerken met ons dan kies je voor een persoonlijke, confronterende en integrale aanpak. Dat is wat ons onderscheidt."
    />
    <GridContainer>
      <WelcomeContainer>
        <WelcomeTitle>Samen in HRM</WelcomeTitle>
        <WelcomeTextContainer>
          <Subtitle>
            Welkom op de Samen in HRM website van De Beukelaar Groep. Hier vind
            je alle informatie over de Samen in HRM dag. Kijk gerust alle
            programma's met de bijbehorende documenten terug om je kennis en
            vaardigheden te verversen.
          </Subtitle>
          <DonwloadLink href="./Programma.pdf">
            Download het programma
          </DonwloadLink>
        </WelcomeTextContainer>
        <WelcomeVideoContainer
          title="Welkom bij deze website"
          src="https://www.youtube.com/embed/VB47uErgUXY"
        />
      </WelcomeContainer>
      <ProgramContainer>
        <ProgramTitle>Introductievideo: Samen in HRM</ProgramTitle>
        <ProgramVideo
          title={""}
          src={"https://www.youtube.com/embed/wfFzmrdmwUU"}
        />
      </ProgramContainer>
      <ProgramContainer>
        <ProgramTitle>Programma 1: Onderzoekend vragen</ProgramTitle>
        <DonwloadLink href="./Ontdekkende vragen.pdf">
          Download de handout: Ontdekkende vragen
        </DonwloadLink>
        <ProgramVideoContainer>
          <ProgramVideo
            title="Ontdekkende vragen stellen - Deel 1"
            src="https://www.youtube.com/embed/vFbC0Y6NVXY"
          />
          <ProgramVideo
            title="Ontdekkende vragen stellen - Deel 2"
            src="https://www.youtube.com/embed/Xy1E6GodUwc"
          />
          <ProgramVideo
            title="Ontdekkende vragen stellen - Deel 3"
            src="https://www.youtube.com/embed/7W2efGwIJe8"
          />
          <ProgramVideo
            title="Ontdekkende vragen stellen - Deel 4"
            src="https://www.youtube.com/embed/W8JL7fBCavk"
          />
          <ProgramVideo
            title="Ontdekkende vragen stellen - Deel 5"
            src="https://www.youtube.com/embed/wbiNgv_Pimc"
          />
        </ProgramVideoContainer>
      </ProgramContainer>
      <ProgramContainer>
        <ProgramTitle>Programma 2: Kunst van conflict</ProgramTitle>
        <DonwloadLink href="./Kunst van conflict.pdf">
          Download de handout: Kunst van conflict
        </DonwloadLink>
        <ProgramVideoContainer>
          <ProgramVideo
            title="Kunst van Conflict - Deel 1"
            src="https://www.youtube.com/embed/XK0Pn_hBolE"
          />
          <ProgramVideo
            title="Kunst van Conflict - Deel 2"
            src="https://www.youtube.com/embed/g7ONzGD8inE"
          />
          <ProgramVideo
            title="Kunst van Conflict - Deel 3"
            src="https://www.youtube.com/embed/X3zg7JlRyk0"
          />
          <ProgramVideo
            title="Kunst van Conflict - Deel 4a"
            src="https://www.youtube.com/embed/wyj7E8dnFoQ"
          />
          <ProgramVideo
            title="Kunst van Conflict - Deel 4b"
            src="https://www.youtube.com/embed/r16QVzt-JHc"
          />
          <ProgramVideo
            title="Kunst van Conflict - Deel 5"
            src="https://www.youtube.com/embed/LNW4hVwxXcY"
          />
        </ProgramVideoContainer>
      </ProgramContainer>
      <ProgramContainer>
        <ProgramTitle>Programma 3: Teamontwikkeling</ProgramTitle>
        <DonwloadLink href="./Teamontwikkeling.pdf">
          Download de handout: Teamontwikkeling
        </DonwloadLink>
        <ProgramVideoContainer>
          <ProgramVideo
            title="Teamontwikkeling - Deel 1"
            src="https://www.youtube.com/embed/A6d1-0Eo1IM"
          />
          <ProgramVideo
            title="Teamontwikkeling - Deel 2"
            src="https://www.youtube.com/embed/54ry9at7egY"
          />
          <ProgramVideo
            title="Teamontwikkeling - Deel 3"
            src="https://www.youtube.com/embed/4y9F82bblJw"
          />
        </ProgramVideoContainer>
      </ProgramContainer>
      <ProgramContainer>
        <ProgramTitle>Programma 4: Storytelling</ProgramTitle>
        <DonwloadLink href="./Storytelling.pdf">
          Download de handout: Storytelling
        </DonwloadLink>
        <ProgramVideoContainer>
          <ProgramVideo
            title="Storytelling - Deel 1"
            src="https://www.youtube.com/embed/KQf-tiaqYwo"
          />
          <ProgramVideo
            title="Storytelling - Deel 2"
            src="https://www.youtube.com/embed/p6BL1BGqVxE"
          />
          <ProgramVideo
            title="Storytelling - Deel 3"
            src="https://www.youtube.com/embed/yv6B-NMWEKw"
          />
        </ProgramVideoContainer>
      </ProgramContainer>
    </GridContainer>
  </>
)

export default IndexPage
